import Alpine from 'alpinejs';

Alpine.start()

document.addEventListener('DOMContentLoaded', function () {
    (function () {
        document.querySelectorAll('a[rel=external]').forEach(function (link) {
            link.addEventListener('click', function (event) {
                event.preventDefault();

                window.open(link.href);
            });
        });
    })();
});
